<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span> {{ $t('edit') }} {{ $tc('entity.company', 1) }} </span>
    </div>
    <el-form
      ref="formCompany"
      v-loading="loading"
      :model="company"
      label-position="top"
      :rules="rulesCompany"
    >
      <el-row :gutter="20">
        <el-col :sm="24" :md="8">
          <el-form-item :label="$tc('name', 1)" prop="companyName">
            <el-input
              v-model="company.companyName"
              type="text"
              :maxlength="160"
            ></el-input>
          </el-form-item>
          <el-form-item prop="companyIdentification">
            <span slot="label"
              >{{ $t('identification') }}
              <el-tooltip
                content="Ingrese un número sin puntos, ni comas, ni guiones, ni espacios"
                placement="right"
              >
                <el-button size="mini" type="text">
                  <i class="text-info far fa-question-circle"></i>
                </el-button>
              </el-tooltip>
            </span>
            <el-input v-model="company.companyIdentification" :maxlength="24">
              <template slot="append"> - {{ dv }} </template>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="$t('identificationType')"
            prop="identificationTypeId"
          >
            <select-identification-type
              v-model="company.identificationTypeId"
            ></select-identification-type>
          </el-form-item>
          <el-form-item :label="$t('email')" prop="companyEmail">
            <el-input
              v-model="company.companyEmail"
              type="email"
              :maxlength="160"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('country', 1)" prop="countryId">
            <select-country
              v-model="company.countryId"
              @set-name="setCountryName"
            ></select-country>
          </el-form-item>
          <el-form-item :label="$t('region')" prop="companyRegion">
            <select-region
              v-if="company.countryId === 'CO'"
              v-model="company.regionId"
              @set-name="setRegionName"
            ></select-region>
            <el-input
              v-if="company.countryId !== 'CO'"
              v-model="company.companyRegion"
              :maxlength="80"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('city', 1)" prop="companyCity">
            <select-city
              v-if="company.countryId === 'CO'"
              v-model="company.cityId"
              :selected-region="company.regionId"
              @set-name="setCityName"
            ></select-city>
            <el-input
              v-if="company.countryId !== 'CO'"
              v-model="company.companyCity"
              :maxlength="80"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('personType')" prop="personTypeId">
            <el-select v-model="company.personTypeId">
              <el-option value="1" label="Persona Juridica"></el-option>
              <el-option value="2" label="Persona Natural"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('regimeType')" prop="regimeTypeId">
            <el-select v-model="company.regimeTypeId">
              <el-option
                value="48"
                label="Impuesto sobre las ventas – IVA"
              ></el-option>
              <el-option value="49" label="No responsable de IVA"></el-option>
              <!-- <el-option value="05" label="Régimen Ordinario"></el-option>
              <el-option value="04" label="Régimen Simple"></el-option> -->
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item :label="$t('website')">
            <el-input
              v-model="company.companyWebsite"
              type="url"
              :maxlength="160"
              class="input-with-select"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('phone')">
            <el-input
              v-model="company.companyPhone"
              type="text"
              :maxlength="24"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('address', 1)">
            <el-input
              v-model="company.companyAddress"
              type="text"
              :maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('postalCode')">
            <el-input
              v-model="company.companyZipCode"
              type="text"
              :maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('currency')" prop="currencyId">
            <select-currency-global
              v-model="company.currencyId"
            ></select-currency-global>
          </el-form-item>
          <el-form-item :label="$t('dateFormat')">
            <el-select v-model="company.companyDateFormat">
              <el-option-group>
                <el-option
                  value="MM-DD-YYYY"
                  label="[MM-DD-YYYY] 03-25-2019"
                ></el-option>
                <el-option
                  value="MM/DD/YYYY"
                  label="[MM/DD/YYYY] 03/25/2019"
                ></el-option>
                <el-option
                  value="MM.DD.YYYY"
                  label="[MM.DD.YYYY] 03.25.2019"
                ></el-option>
              </el-option-group>
              <el-option-group>
                <el-option
                  value="DD-MM-YYYY"
                  label="[DD-MM-YYYY] 25-03-2019"
                ></el-option>
                <el-option
                  value="DD/MM/YYYY"
                  label="[DD/MM/YYYY] 25/03/2019"
                ></el-option>
                <el-option
                  value="DD.MM.YYYY"
                  label="[DD.MM.YYYY] 25.03.2019"
                ></el-option>
              </el-option-group>
              <el-option-group>
                <el-option
                  value="YYYY-MM-DD"
                  label="[YYYY-MM-DD] 2019-03-25"
                ></el-option>
                <el-option
                  value="YYYY/MM/DD"
                  label="[YYYY/MM/DD] 2019/03/25"
                ></el-option>
                <el-option
                  value="YYYY.MM.DD"
                  label="[YYYY.MM.DD] 2019.03.25"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('timeFormat')">
            <el-select v-model="company.companyTimeFormat">
              <el-option value="HH:mm" label="[24 H] 20:25"></el-option>
              <el-option value="hh:mm a" label="[12 H] 08:25 pm"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="8">
          <el-form-item :label="$t('timeZone')" prop="timeZoneId">
            <select-time-zone v-model="company.timeZoneId"></select-time-zone>
          </el-form-item>
          <el-form-item :label="$t('culture')" prop="cultureId">
            <select-culture v-model="company.cultureId"></select-culture>
          </el-form-item>
          <el-form-item :label="$t('language')" prop="languageId">
            <select-language v-model="company.languageId"></select-language>
          </el-form-item>
          <el-form-item :label="$tc('isPartner', 1)">
            <el-switch
              v-model="company.companyIsPartner"
              @change="isPartner"
            ></el-switch>
          </el-form-item>
          <el-form-item
            v-if="!company.companyIsPartner"
            :label="$tc('partner', 1)"
          >
            <select-partner
              v-model="company.idParent"
              @set-partner="setPartner"
            ></select-partner>
          </el-form-item>
          <el-form-item
            v-if="!company.companyIsPartner"
            :label="$tc('invoiceToPartner', 1)"
          >
            <el-switch v-model="company.companyInvoiceToPartner"></el-switch>
          </el-form-item>
          <el-form-item
            v-if="!company.companyInvoiceToPartner"
            :label="$tc('person', 1)"
          >
            <select-person
              v-model="company.commonPersonId"
              type="customer"
            ></select-person>
          </el-form-item>
          <!-- <el-form-item :label="$tc('person',1)" prop="personId">
            <select-people-nubixar v-model="company.commonPersonId"></select-people-nubixar>
          </el-form-item> -->
        </el-col>
      </el-row>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          :loading="loading"
          @click="update"
        >
          {{ $t('save') }}
        </el-button>
        <router-link
          to="/companies"
          class="el-button el-button--text el-button--small"
          tag="button"
        >
          {{ $t('cancel') }}
        </router-link>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { calcularDv } from '../../assets/js/nbxr'
import { i18n } from '../../i18n'
import companyMix from './company-mix'
import model from './model'

export default {
  name: 'CompanyUpdate',
  mixins: [companyMix],
  data() {
    return {
      company: model(),
      urlType: 'http://',
      token: localStorage.getItem('token'),
      loading: false,
      hasLogo: false,
      rulesCompany: {
        companyName: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
        ],
        companyIdentification: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
        ],
        identificationTypeId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        companyCity: [
          { required: true, message: this.$t('required'), trigger: 'blur' },
        ],
        companyRegion: [
          { required: true, message: this.$t('required'), trigger: 'blur' },
        ],
        countryId: [
          {
            required: true,
            message: this.$t('required'),
            trigger: 'blur,change',
          },
        ],
        currencyId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        timeZoneId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        cultureId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        languageId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        personTypeId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        regimeTypeId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        companyEmail: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
          {
            type: 'email',
            message: i18n.t('invalidEmail'),
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  computed: {
    dv() {
      return calcularDv(this.company.companyIdentification)
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    update() {
      const _this = this
      _this.$refs.formCompany.validate(valid => {
        if (valid) {
          _this.loading = true
          _this.company.companyIdentificationCheck = calcularDv(
            _this.company.companyIdentification,
          )
          _this.$http.put('companies', _this.company).then(
            response => {
              _this.$swal(i18n.t('ok'), i18n.t('processSuccess'), 'success')
              _this.loading = false
            },
            response => {
              _this.loading = false
            },
          )
        } else {
          _this.$message.error('Oops, hay datos requeridos que debes ingresar!')

          return false
        }
      })
    },
    setPartner(value) {
      const _this = this
      _this.loading = true

      // console.log('SetPartner', value)
      if (value) {
        _this.company.idParent = value.id
        _this.company.companyKeyParent = value.companyKey
      } else {
        _this.company.idParent = ''
        _this.company.companyKeyParent = ''
      }
      _this.loading = false
    },
    isPartner(value) {
      const _this = this
      _this.loading = true
      if (!value) {
        _this.company.idParent = ''
        _this.company.companyKeyParent = ''
      }
      _this.company.companyInvoiceToPartner = false
      _this.loading = false
    },
    get() {
      const _this = this
      _this.loading = true
      _this.$http.get(`companies/${_this.$route.params.id}`).then(
        response => {
          _this.loading = false
          _this.company = response.body
        },
        response => {
          _this.loading = false
        },
      )
    },
  },
}
</script>
